import React from 'react';
import { withNamespaces } from "react-i18next";

import camCoverShop from "./../../assets/images/shop/camcover_shop.png";
import phoneCoverShop from "./../../assets/images/shop/phonecover_shop.png";
import iphoneCoverShop from "./../../assets/images/shop/iphone-x_shop.png";
import s9CoverShop from "./../../assets/images/shop/S9_shop.png";
import s10CoverShop from "./../../assets/images/shop/S10_shop.png";

import shirtShop from "./../../assets/images/shop/shirt_shop.png";

import phoneCoverShopLB2 from "./../../assets/images/snap_in_back_cover_plus_oben_auf.png";
import phoneCoverShopLB3 from "./../../assets/images/snap_in_back_cover_plus_oben_zu.png";
import phoneCoverShopLB4 from "./../../assets/images/snap_in_back_cover_plus_unten_auf.png";
import phoneCoverShopLB5 from "./../../assets/images/snap_in_back_cover_plus_unten_zu.png";

import phoneCoverShopIPhoneX_0 from "./../../assets/images/iphone-x/iphone-x.png";
import phoneCoverShopIPhoneX_1 from "./../../assets/images/iphone-x/iphone-x_Cover_A_offen.png";
import phoneCoverShopIPhoneX_2 from "./../../assets/images/iphone-x/iphone-x_Cover_A_zu.png";
import phoneCoverShopIPhoneX_3 from "./../../assets/images/iphone-x/iphone-x_Cover_B_offen.png";
import phoneCoverShopIPhoneX_4 from "./../../assets/images/iphone-x/iphone-x_Cover_B_zu.png";

import phoneCoverShopS9_0 from "./../../assets/images/S9/S9.png";
import phoneCoverShopS9_1 from "./../../assets/images/S9/S8_S9_Cover_A_offen.png";
import phoneCoverShopS9_2 from "./../../assets/images/S9/S8_S9_Cover_A_zu.png";
import phoneCoverShopS9_3 from "./../../assets/images/S9/S8_S9_Cover_B_offen.png";
import phoneCoverShopS9_4 from "./../../assets/images/S9/S8_S9_Cover_B_zu.png";

import phoneCoverShopS10_0 from "./../../assets/images/S10/S10.png";
import phoneCoverShopS10_1 from "./../../assets/images/S10/S10_Cover_A_offen.png";
import phoneCoverShopS10_2 from "./../../assets/images/S10/S10_Cover_A_zu.png";
import phoneCoverShopS10_3 from "./../../assets/images/S10/S10_Cover_B_offen.png";
import phoneCoverShopS10_4 from "./../../assets/images/S10/S10_Cover_B_zu.png";

import Zertifikate_N18 from "./../../assets/images/Zertifikate_N18.png";

class Shop extends React.Component {
  constructor() {
    super();

    this.shops = [];

    this.setShop1Ref = element => {
      this.shops.push(element);
    };
    this.setShop2Ref = element => {
      this.shops.push(element);
    };
    this.setShop3Ref = element => {
      this.shops.push(element);
    };
    this.setShopRefIphone = element => {
      this.shops.push(element)
    };
    this.setShopRefS9 = element => {
      this.shops.push(element)
    };
    this.setShopRefS10 = element => {
      this.shops.push(element)
    };
  }

  componentDidMount() {
    var UIkit = require('../../../node_modules/uikit/dist/js/uikit.js');
    window.UIkit = UIkit;

    this.lightboxes = [
      {
        lightbox: UIkit.lightboxPanel({
          items: [
            {source: phoneCoverShop},
            {source: phoneCoverShopLB2},
            {source: phoneCoverShopLB3},
            {source: phoneCoverShopLB4},
            {source: phoneCoverShopLB5},
          ]
        })
      },
      {
        lightbox: UIkit.lightboxPanel({
          items: [
            { source: phoneCoverShopIPhoneX_0 },
            { source: phoneCoverShopIPhoneX_1 },
            { source: phoneCoverShopIPhoneX_2 },
            { source: phoneCoverShopIPhoneX_3 },
            { source: phoneCoverShopIPhoneX_4 },
          ]
        })
      },
      {
        lightbox: UIkit.lightboxPanel({
          items: [
            { source: phoneCoverShopS9_0 },
            { source: phoneCoverShopS9_1 },
            { source: phoneCoverShopS9_2 },
            { source: phoneCoverShopS9_3 },
            { source: phoneCoverShopS9_4 },
          ]
        })
      },
      {
        lightbox: UIkit.lightboxPanel({
          items: [
            { source: phoneCoverShopS10_0 },
            { source: phoneCoverShopS10_1 },
            { source: phoneCoverShopS10_2 },
            { source: phoneCoverShopS10_3 },
            { source: phoneCoverShopS10_4 },
          ]
        })
      },
      {
        lightbox: UIkit.lightboxPanel({
          items: [
            {source: camCoverShop}
          ]
        })
      },
      {
        lightbox: UIkit.lightboxPanel({
          items: [
            {source: shirtShop},
            {source: Zertifikate_N18}
          ]
        })
      }
    ];

    this.showLightbox = (i) => {
      this.shops[i].style.cursor = 'pointer';
      this.shops[i].onclick = () => {
        if(this.lightboxes[i]) {
          this.lightboxes[i].lightbox.show(0);
        }
      };
    }

    for (var i = 0; i < this.shops.length; i++) {
      this.showLightbox(i);
    }
  }

  handleClick(url) {
    return gtag_report_conversion(url);
  };


  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="uk-section uk-section-default uk-section-large" id="shop">
          <div className="uk-container uk-container-medium">
            <h2 className="uk-margin-large-bottom uk-text-center bm-shop__headline">{t("Shop")}</h2>

            <div className="uk-card uk-card-default uk-text-center uk-grid-collapse uk-child-width-1-2@s uk-margin-large-bottom bm-shop__card" data-uk-grid>
              <div className="uk-card-media-left uk-cover-container bm-shop__card__img bm-shop__card__1--click" ref={this.setShop1Ref}>
                <img className="uk-border-circle" src={phoneCoverShop} alt="" data-uk-cover />
                <canvas width="500" height="500"></canvas>
              </div>
              <div className="bm-shop__card--backgroundColor">
                  <div className="uk-card-body uk-light">
                      <h3 className="uk-card-title bm-shop__card__title">{t("Handyhülle")}</h3>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("Geeignet für Apple iPhone 8, 7 und 6/6s*")}</div>
                      </div>
                      <div className="uk-text-meta uk-padding-small bm-shop__card__info">
                        <p>{t("*Apple and iPhone are trademarks of Apple Inc")}</p>
                      </div>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("inkl. Echtglas Display Schutz")}</div>
                      </div>
                      {/*
                      <div className="bm-shop__card__price">
                        <p>{t("29,90 EUR")} <br /><small>{t("inkl. MwSt.")}</small></p>
                      </div>
                      */}
                  </div>
                  <div className="uk-card-footer">
                    <a onClick={() => {this.handleClick("https://www.amazon.de/blindmonkey-Handyh%C3%BClle-Kameraabdeckung-Schutzh%C3%BClle-Kameraschutz-Schwarz/dp/B07BY5VT73/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=blindmonkey&qid=1567081178&s=gateway&sr=8-2")}} href="https://www.amazon.de/blindmonkey-Handyh%C3%BClle-Kameraabdeckung-Schutzh%C3%BClle-Kameraschutz-Schwarz/dp/B07BY5VT73/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=blindmonkey&qid=1567081178&s=gateway&sr=8-2" target="_blank" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BEI AMAZON KAUFEN")}</a>
                    {/* <a onClick={() => {this.handleClick("mailto:mail@blindmonkey.de")}} href="mailto:mail@blindmonkey.de" target="_self" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BESTELLEN VIA EMAIL")}</a> */}
                  </div>
              </div>
            </div>

            <div className="uk-card uk-card-default uk-text-center uk-grid-collapse uk-child-width-1-2@s uk-margin-large-bottom bm-shop__card" data-uk-grid>
              <div className="bm-shop__card--backgroundColor">
                  <div className="uk-card-body uk-light">
                      <h3 className="uk-card-title bm-shop__card__title">{t("Handyhülle")}</h3>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("Geeignet für Apple iPhone X und Xs*")}</div>
                      </div>
                      <div className="uk-text-meta uk-padding-small bm-shop__card__info">
                        <p>{t("*Apple and iPhone are trademarks of Apple Inc")}</p>
                      </div>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("inkl. Echtglas Display Schutz")}</div>
                      </div>
                      {/*
                      <div className="bm-shop__card__price">
                        <p>{t("24,90 EUR_IPhoneX")} <br /><small>{t("inkl. MwSt.")}</small></p>
                      </div> 
                      */}
                  </div>
                  <div className="uk-card-footer">
                    <a onClick={() => {this.handleClick("https://www.amazon.de/blindmonkey-Handyh%C3%BClle-Schiebeh%C3%BClle-Kameraabdeckung-Schutzglas-Schwarz/dp/B07TT6WB95?ref_=bl_dp_s_web_14848277031")}} href="https://www.amazon.de/blindmonkey-Handyh%C3%BClle-Schiebeh%C3%BClle-Kameraabdeckung-Schutzglas-Schwarz/dp/B07TT6WB95?ref_=bl_dp_s_web_14848277031" target="_blank" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BEI AMAZON KAUFEN")}</a>
                    {/* <a onClick={() => {this.handleClick("mailto:mail@blindmonkey.de")}} href="mailto:mail@blindmonkey.de" target="_self" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BESTELLEN VIA EMAIL")}</a> */}
                  </div>
              </div>
              <div className="uk-card-media-right uk-cover-container bm-shop__card__img bm-shop__card__1--click" ref={this.setShopRefIphone}>
                <img className="uk-border-circle" src={iphoneCoverShop} alt="" data-uk-cover />
                <canvas width="500" height="500"></canvas>
              </div>
            </div>

            <div className="uk-card uk-card-default uk-text-center uk-grid-collapse uk-child-width-1-2@s uk-margin-large-bottom bm-shop__card" data-uk-grid>
              <div className="uk-card-media-left uk-cover-container bm-shop__card__img bm-shop__card__1--click" ref={this.setShopRefS9}>
                <img className="uk-border-circle" src={s9CoverShop} alt="" data-uk-cover />
                <canvas width="500" height="500"></canvas>
              </div>
              <div className="bm-shop__card--backgroundColor">
                  <div className="uk-card-body uk-light">
                      <h3 className="uk-card-title bm-shop__card__title">{t("Handyhülle")}</h3>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("Geeignet für Samsung Galaxy S9*")}</div>
                      </div>
                      <div className="uk-text-meta uk-padding-small bm-shop__card__info">
                        <p>{t("*Samsung and Samsung Galaxy S9 are trademarks of Samsung")}</p>
                      </div>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("inkl. Echtglas Display Schutz")}</div>
                      </div>
                      {/*
                      <div className="bm-shop__card__price">
                        <p>{t("24,90 EUR_S9")} <br /><small>{t("inkl. MwSt.")}</small></p>
                      </div>
                      */}
                  </div>
                  <div className="uk-card-footer">
                    <a onClick={() => {this.handleClick("https://www.amazon.de/blindmonkey-Handyh%C3%BClle-Schiebeh%C3%BClle-Kameraabdeckung-Schutzglas/dp/B07TX92JKZ?ref_=bl_dp_s_web_14848277031")}} href="https://www.amazon.de/blindmonkey-Handyh%C3%BClle-Schiebeh%C3%BClle-Kameraabdeckung-Schutzglas/dp/B07TX92JKZ?ref_=bl_dp_s_web_14848277031" target="_blank" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BEI AMAZON KAUFEN")}</a>
                    {/* <a onClick={() => {this.handleClick("mailto:mail@blindmonkey.de")}} href="mailto:mail@blindmonkey.de" target="_self" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BESTELLEN VIA EMAIL")}</a> */}
                  </div>
              </div>
            </div>

            <div className="uk-card uk-card-default uk-text-center uk-grid-collapse uk-child-width-1-2@s uk-margin-large-bottom bm-shop__card" data-uk-grid>
              <div className="bm-shop__card--backgroundColor">
                  <div className="uk-card-body uk-light">
                      <h3 className="uk-card-title bm-shop__card__title">{t("Handyhülle")}</h3>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("Geeignet für Samsung Galaxy S10*")}</div>
                      </div>
                      <div className="uk-text-meta uk-padding-small bm-shop__card__info">
                        <p>{t("*Samsung and Samsung Galaxy S10 are trademarks of Samsung")}</p>
                      </div>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("inkl. Echtglas Display Schutz")}</div>
                      </div>
                      {/*
                      <div className="bm-shop__card__price">
                        <p>{t("24,90 EUR_S10")} <br /><small>{t("inkl. MwSt.")}</small></p>
                      </div>
                      */}
                  </div>
                  <div className="uk-card-footer">
                    <a onClick={() => {this.handleClick("https://www.amazon.de/blindmonkey-Handyh%C3%BClle-Schiebeh%C3%BClle-Kameraabdeckung-Schutzglas/dp/B07TZGJH3W?ref_=bl_dp_s_web_14848277031")}} href="https://www.amazon.de/blindmonkey-Handyh%C3%BClle-Schiebeh%C3%BClle-Kameraabdeckung-Schutzglas/dp/B07TZGJH3W?ref_=bl_dp_s_web_14848277031" target="_blank" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BEI AMAZON KAUFEN")}</a>
                    {/* <a onClick={() => {this.handleClick("mailto:mail@blindmonkey.de")}} href="mailto:mail@blindmonkey.de" target="_self" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BESTELLEN VIA EMAIL")}</a> */}
                  </div>
              </div>
              <div className="uk-card-media-right uk-cover-container bm-shop__card__img bm-shop__card__1--click" ref={this.setShopRefS10}>
                <img className="uk-border-circle" src={s10CoverShop} alt="" data-uk-cover />
                <canvas width="500" height="500"></canvas>
              </div>
            </div>

            <div className="uk-card uk-card-default uk-text-center uk-grid-collapse uk-child-width-1-2@s uk-margin-large-bottom bm-shop__card" data-uk-grid>
              <div className="uk-card-media-left uk-cover-container bm-shop__card__img bm-shop__card__2--click" ref={this.setShop2Ref}>
                  <img className="uk-border-circle" src={camCoverShop} alt="" data-uk-cover />
                  <canvas width="500" height="500"></canvas>
              </div>
              <div className="bm-shop__card--backgroundColor">
                  <div className="uk-card-body uk-light">
                      <h3 className="uk-card-title bm-shop__card__title">{t("Cam Cover")}</h3>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("Webcamabdeckung geeignet für Geräte")}</div>
                      </div>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("5er-Pack")}</div>
                      </div>
                      {/*
                      <div className="bm-shop__card__price">
                        <p>{t("14,90 EUR")} <br /><small>{t("inkl. MwSt.")}</small></p>
                      </div>
                      */}
                  </div>
                  <div className="uk-card-footer">
                    <a onClick={() => {this.handleClick("https://www.amazon.de/blindmonkey-Webcam-Abdeckung-Spionage-Cover-Schwarz/dp/B079YSHLC7/ref=sr_1_2?fst=as%3Aoff&keywords=webcam+sichtschutz&qid=1567081290&refinements=p_89%3ABlindmonkey&rnid=669059031&s=gateway&sr=8-2")}} href="https://www.amazon.de/blindmonkey-Webcam-Abdeckung-Spionage-Cover-Schwarz/dp/B079YSHLC7/ref=sr_1_2?fst=as%3Aoff&keywords=webcam+sichtschutz&qid=1567081290&refinements=p_89%3ABlindmonkey&rnid=669059031&s=gateway&sr=8-2" target="_blank" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BEI AMAZON KAUFEN")}</a>
                    {/* <a onClick={() => {this.handleClick("mailto:mail@blindmonkey.de")}} href="mailto:mail@blindmonkey.de" target="_self" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BESTELLEN VIA EMAIL")}</a> */}
                  </div>
              </div>
            </div>

            <div className="uk-card uk-card-default uk-text-center uk-grid-collapse uk-child-width-1-2@s uk-margin-large-bottom bm-shop__card" data-uk-grid>
              <div className="bm-shop__card--backgroundColor">
                  <div className="uk-card-body uk-light">
                      <h3 className="uk-card-title bm-shop__card__title">{t("Shirt")}</h3>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("Slim Cut")}</div>
                      </div>
                      <div className="uk-text-meta uk-padding-small bm-shop__card__info">
                        <p>{t("Material: 85% Baumwolle, 15% Viscose 150g/m²")}</p>
                        <p>{t("Farbe: Melange Grey")}</p>
                      </div>
                      <div className="uk-text-lead uk-padding-small bm-shop__card__description">
                        <div>{t("Men: M, L, XL")} <br /> {t("Women: S, M , L")}</div>
                      </div>
                      {/*
                      <div className="bm-shop__card__price">
                        <p>{t("27,95 EUR")} <br /><small>{t("inkl. MwSt.")}</small></p>
                      </div>

                      <div className="uk-text-meta bm-shop__card__info">
                        <p><small>{t("zzgl. Versandkosten")}</small></p>
                      </div>
                      */}
                  </div>
                  <div className="uk-card-footer">
                    <a onClick={() => {this.handleClick("mailto:mail@blindmonkey.de")}} href="mailto:mail@blindmonkey.de" target="_self" className="uk-button uk-button-secondary uk-button-large uk-margin-small-bottom bm-shop__card__buy">{t("BESTELLEN VIA EMAIL")}</a>
                  </div>
              </div>
              <div className="uk-card-media-right uk-cover-container bm-shop__card__img bm-shop__card__1--click" ref={this.setShop3Ref}>
                <img className="uk-border-circle" src={shirtShop} alt="" data-uk-cover />
                <canvas width="500" height="500"></canvas>
              </div>
            </div>

          </div>

        </div>
      </div>
    )
  }
}

export default withNamespaces("Shop")(Shop);
