import React from 'react'

// import { ReactComponent as LogoMonkey } from "./../../assets/icons/logo_hoch-circle.svg";
import { ReactComponent as LogoMonkey } from "./../../assets/icons/logo_hoch-circle_no_text.svg";

import "./herofooter.scss";

const HeroFooter = () => (
    <div className="uk-container">
      <div className="uk-flex uk-flex-center">
        <div className="bm-herofooter__logo">
          <LogoMonkey width="100" className="uk-position-relative uk-position-bottom-center	bm-logo__img--margin" />
          <h1 className="bm-herofooter__title">blindmonkey  &reg;</h1>
        </div>
      </div>
    </div>
)

export default HeroFooter
