import React from 'react'
import { graphql } from 'gatsby';

import i18n from '../i18n';

import Layout from '../components/layout';

import Header from '../components/Header'
import PhoneCover from '../components/PhoneCover'
import PhoneFeatures from '../components/PhoneFeatures'

import CamCover from '../components/CamCover'
import CamFeatures from '../components/CamFeatures'

import Shop from '../components/Shop'
import Footer from '../components/Footer'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout siteMetadata={this.props.data.site.siteMetadata}>
        <div>
          <Header props={this.props} />
          <PhoneCover props={this.props.data} />
          <PhoneFeatures />
          <CamCover />
          <CamFeatures />
          <Shop />
          <Footer />
        </div>
      </Layout>
    )
  }
}

export default IndexPage;

export const query = graphql`
  query MetaData {
    site {
      siteMetadata {
        title
        description,
        keywords,
        siteUrl,
        author,
        twitter,
        adsense
      }
    }
  }
`
