import React from 'react'
import { withNamespaces } from "react-i18next";

import { ReactComponent as PrivacyProtectionIcon } from "./../../assets/icons/privacy_protection.svg";
import { ReactComponent as CamProtectionIcon } from "./../../assets/icons/cam_protection.svg";
import { ReactComponent as SlimDesignIcon } from "./../../assets/icons/slim_design.svg";
import { ReactComponent as MechanicalClosureIcon } from "./../../assets/icons/mechanical_closure.svg";

import { ReactComponent as InfoCircleIcon } from "./../../assets/icons/fa/info-circle_white.svg";

import { ReactComponent as AntiSpyWhiteIcon } from "./../../assets/icons/anti_spy-white.svg";

class PhoneFeatures extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="uk-section uk-section-secondary uk-padding-remove-bottom bm-overflow--hidden">
        <div className="uk-container uk-container-large">
          <div className="uk-child-width-1-4@l uk-child-width-1-2@m uk-grid uk-text-center uk-margin-medium-top" data-uk-grid>
            <div>
              <div className="uk-flex uk-flex-1 uk-flex-center uk-flex-column bm-features__box" data-uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true; delay: 400">
                <PrivacyProtectionIcon width="50" className="bm-features__box__img" />
                <h4 className="uk-margin-small bm-features__box__heading">{t("Schutz der Privatsphäre")}</h4>
              </div>
            </div>

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-center uk-flex-column bm-features__box" data-uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true; delay: 200">
                <CamProtectionIcon width="50" className="bm-features__box__img" />
                <h4 className="uk-margin-small bm-features__box__heading">{t("Kameraschutz")}</h4>
              </div>
            </div>

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-center uk-flex-column bm-features__box" data-uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true; delay: 200">
                <SlimDesignIcon width="50" className="bm-features__box__img" />
                <h4 className="uk-margin-small bm-features__box__heading">{t("Schlankes Design")}</h4>
              </div>
            </div>

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-center uk-flex-column bm-features__box" data-uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true; delay: 400">
                <MechanicalClosureIcon width="50" className="bm-features__box__img" />
                <h4 className="uk-margin-small bm-features__box__heading">{t("Mechanischer Verschluss")}</h4>
              </div>
            </div>
          </div>

          <div className="uk-child-width-1-2@m uk-grid uk-grid-collapse uk-padding uk-flex-center uk-grid-match" data-uk-grid data-uk-scrollspy="cls: uk-animation-fade; target: > div > .bm-features__textbox; delay: 500; repeat: true">

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-row uk-flex-middle uk-padding-small bm-features__textbox">
                <InfoCircleIcon width="40" className="" />
                <p className="uk-padding-small">{t("Die Lösung gegen unerlaubte Zugriffe auf deine Handykamera")}</p>
              </div>
            </div>

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-row uk-flex-middle uk-padding-small bm-features__textbox">
                <InfoCircleIcon width="40" className="" />
                <p className="uk-padding-small">{t("Diese innovative Handyhülle verfügt über einen mechanischen Kameraverschluss")}</p>
              </div>
            </div>

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-row uk-flex-middle uk-padding-small bm-features__textbox">
                <InfoCircleIcon width="40" className="" />
                <p className="uk-padding-small">{t("Mit dieser genialen Handyhülle sicherst du die Kamera deines Smartphones")}</p>
              </div>
            </div>

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-row uk-flex-middle uk-padding-small bm-features__textbox">
                <InfoCircleIcon width="40" className="" />
                <p className="uk-padding-small">{t("Gleichzeitig schützt die Handyhülle sowohl die Frontkamera")}</p>
              </div>
            </div>

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-row uk-flex-middle uk-padding-small bm-features__textbox">
                <InfoCircleIcon width="40" className="" />
                <p className="uk-padding-small">{t("SPECIAL ADD-ON!")}</p>
              </div>
            </div>

            <div>
              <div className="uk-flex uk-flex-1 uk-flex-row uk-flex-middle uk-padding-small bm-features__textbox">
                <InfoCircleIcon width="40" className="" />
                <p className="uk-padding-small">{t("Zeit zu handeln!")}</p>
              </div>
            </div>

          </div>

        </div>
        <div className="bm-features__antibanner">
          <AntiSpyWhiteIcon className="" />
        </div>
      </div>
    )
  }
}

export default withNamespaces("PhoneFeatures")(PhoneFeatures);
