import React from 'react';
import { Link } from 'gatsby'
import { withNamespaces } from "react-i18next";

import camCoverStatic from "./../../assets/images/camcover_zu-auf-animation-1.png";
import camCoverMove1 from "./../../assets/images/camcover_zu-auf-animation-2.png";

class CamCover extends React.Component {
  componentDidMount() {
    var ScrollMagic = require('../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic.js');
    if(!window.ScrollMagic) {
      window.ScrollMagic = ScrollMagic;
    }

    var controller = new ScrollMagic.Controller();

    var camMove1 = document.getElementById("camCoverMove1");

    var scene = new ScrollMagic.Scene({triggerElement: "#camcover", duration: 320})
      .addTo(controller)
      .on('progress', function(e){
        camMove1.style.transform = "translate("+ (e.progress * 38) +"%, 0px)";
      })
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="uk-section uk-section-default uk-section-large" id="camcover">
          <div className="uk-container uk-container-medium">
            <div className="uk-child-width-1-2@m uk-grid uk-text-center" data-uk-grid>
              <span className="uk-flex uk-flex-center uk-flex-column bm-phonecover__left">
                <div className="uk-flex-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                  <h2 className="uk-text-center bm-width--full">{t("Cam Cover")}</h2>
                  <p>
                    <Link className="uk-button uk-button-primary uk-button-large uk-margin-small-bottom bm-shop__card__buy" to="#shop" data-uk-scroll>{t("JETZT KAUFEN")}</Link>
                  </p>
                </div>
              </span>

              <span className="uk-flex uk-flex-center bm-camcover__img uk-flex-bottom">
                <img src={camCoverStatic} alt="CoverOpen" id="camCoverStatic" className="uk-position-absolute"/>
                <img src={camCoverMove1} alt="CoverOpen" id="camCoverMove1" className="uk-position-relative"/>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withNamespaces("CamCover")(CamCover);
