
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby';

import '../assets/scss/main.scss';


// import UIkit from 'uikit';
// import Icons from 'uikit/dist/js/uikit-icons';


class Template extends React.Component {
  componentDidMount() {
    var UIkit = require('uikit');
    // UIkit.use(Icons);

    if (typeof window !== 'undefined') {
      window.UIkit = UIkit;
    }
  }

  render() {
    const { children, siteMetadata } = this.props;
    return (
        <div>
          <Helmet
            title={ siteMetadata.title }
            meta={[
              { name: 'description', content: siteMetadata.description },
              { name: 'author', content: siteMetadata.author },
              { property: 'og:url', content: siteMetadata.siteUrl },
              { property: 'og:type', content: 'website' },
              { property: 'og:title', content: siteMetadata.title },
              { property: 'og:description', content: siteMetadata.description },
            ]}
          >
          </Helmet>
          <div>
            {children}
          </div>
        </div>
    );
  }
}

Template.propTypes = {
  route: PropTypes.object,
}

export default Template;

