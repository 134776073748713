import React from 'react'

import videoMP4Bad from '../../assets/video/blindmonkey_bad_low.mp4';
import videoVP9Bad from '../../assets/video/blindmonkey_bad_vp9-opus.webm';
import videoVP8Bad from '../../assets/video/blindmonkey_bad_vp8-opus.webm';

import videoMP4Arsch from '../../assets/video/blindmonkey_dein_arsch_low.mp4';
import videoVP9Arsch from '../../assets/video/blindmonkey_dein_arsch_vp9-opus.webm';
import videoVP8Arsch from '../../assets/video/blindmonkey_dein_arsch_vp8-opus.webm';

import videoMP4Kind from '../../assets/video/blindmonkey_kind_low.mp4';
import videoVP9Kind from '../../assets/video/blindmonkey_kind_vp9-opus.webm';
import videoVP8Kind from '../../assets/video/blindmonkey_kind_vp8-opus.webm';

import videoMP4Om from '../../assets/video/blindmonkey_om_low.mp4';
import videoVP9Om from '../../assets/video/blindmonkey_om_vp9-opus.webm';
import videoVP8Om from '../../assets/video/blindmonkey_om_vp8-opus.webm';

import videoMP4Ost from '../../assets/video/blindmonkey_AndyOst_low.mp4';
import videoVP9Ost from '../../assets/video/blindmonkey_AndyOst_vp9-opus.webm';
import videoVP8Ost from '../../assets/video/blindmonkey_AndyOst_vp8-opus.webm';

import { ReactComponent as IconLeft } from '../../assets/icons/fa/chevron-left__white.svg';
import { ReactComponent as IconRight } from '../../assets/icons/fa/chevron-right__white.svg';


const Hero = () => (

    <div className="uk-cover-container bm-hero__container">

        <div data-uk-slideshow="animation: push; autoplay: true; autoplay-interval: 20000" className="uk-position-relative uk-visible-toggle uk-light">
            <ul className="uk-slideshow-items">
                <li>
                    <video loop muted playsInline data-uk-cover>
                        <source src={videoVP9Ost} type='video/webm; codecs="vp9, opus"' />
                        <source src={videoVP8Ost} type='video/webm; codecs="vp8, opus"' />
                        <source src={videoMP4Ost} type="video/mp4" />
                    </video>
                </li>
                <li>
                    <video loop muted playsInline data-uk-cover>
                        <source src={videoVP9Bad} type='video/webm; codecs="vp9, opus"' />
                        <source src={videoVP8Bad} type='video/webm; codecs="vp8, opus"' />
                        <source src={videoMP4Bad} type="video/mp4" />
                    </video>
                </li>
                <li>
                    <video loop muted playsInline data-uk-cover>
                        <source src={videoVP9Arsch} type='video/webm; codecs="vp9, opus"' />
                        <source src={videoVP8Arsch} type='video/webm; codecs="vp8, opus"' />
                        <source src={videoMP4Arsch} type="video/mp4" />
                    </video>
                </li>
                <li>
                    <video loop muted playsInline data-uk-cover>
                        <source src={videoVP9Kind} type='video/webm; codecs="vp9, opus"' />
                        <source src={videoVP8Kind} type='video/webm; codecs="vp8, opus"' />
                        <source src={videoMP4Kind} type="video/mp4" />
                    </video>
                </li>
                <li>
                    <video loop muted playsInline data-uk-cover>
                        <source src={videoVP9Om} type='video/webm; codecs="vp9, opus"' />
                        <source src={videoVP8Om} type='video/webm; codecs="vp8, opus"' />
                        <source src={videoMP4Om} type="video/mp4" />
                    </video>
                </li>
            </ul>

            <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slideshow-item="previous" data-uk-slidenav-previous>
                <IconLeft width="30" height="100" className="" />
            </a>
            <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slideshow-item="next" data-uk-slidenav-next>
                <IconRight width="30" height="100" className="" />
            </a>

        </div>
    </div>
)
export default Hero
