/*
import i18n from "i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
      load: 'languageOnly',
      nsSeparator: false,
      keySeparator: false,
      pluralSeparator: false,
      contextSeparator: false,
      fallbackLng: 'de',
      ns: ["translations"],
      defaultNS: "translations",
      detection: {
        order: ['path', 'localStorage', 'navigator'],
        lookupLocalStorage: 'i18nextLng',
      },
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
        nsMode: "default"
      },
    });

export default i18n;
*/

import i18n from "i18next";
import XHR from 'i18next-xhr-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

export const options = {
  load: 'languageOnly',
    nsSeparator: false,
    keySeparator: false,
    pluralSeparator: false,
    contextSeparator: false,
    fallbackLng: 'de',
    ns: ["common"],
    defaultNS: "common",
    detection: {
      order: ['path', 'localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      nsMode: "default"
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
}
// for browser use xhr backend to load translations and browser lng detector
i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init(options)

export default i18n;
