import React from 'react'

import Navigation from '../Navigation'
import Hero from '../Hero'
import HeroFooter from '../HeroFooter'

const Header = (props) => (
    <div className="uk-section-default uk-preserve-color" id="home">
      <Hero />
      <Navigation props={props.props} />
      <HeroFooter />
    </div>
)

export default Header
