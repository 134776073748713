import React from 'react'
import { Link } from 'gatsby'
import { withNamespaces } from "react-i18next";

// import coverBackOpen from "./../../assets/images/cover_back_open.png";

import phoneFrontStatic from "./../../assets/images/covervorne_zu-auf-animation-1.png";
import phoneFrontMove1 from "./../../assets/images/covervorne_zu-auf-animation-2.png";
import phoneFrontMove2 from "./../../assets/images/covervorne_zu-auf-animation-3.png";

import phoneBackStatic from "./../../assets/images/coverhinten_zu-auf-animation-2.png";
import phoneBackMove1 from "./../../assets/images/coverhinten_zu-auf-animation-1.png";

class PhoneCover extends React.Component {
  componentDidMount() {
    var ScrollMagic = require('../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic.js');
    window.ScrollMagic = ScrollMagic;

    var controller = new ScrollMagic.Controller();

    var frontMove1 = document.getElementById("phoneFrontMove1");
    var frontMove2 = document.getElementById("phoneFrontMove2");

    var backMove1 = document.getElementById("phoneBackMove1");


    new ScrollMagic.Scene({triggerElement: "#phonecover", duration: 320})
      .addTo(controller)
      .on('progress', function(e){
        frontMove1.style.transform = "translate(0px, "+ (e.progress * 12) +"%)";
        frontMove2.style.transform = "translate(0px, "+ (e.progress * 12) +"%)";

        backMove1.style.transform = "translate(0px, "+ (e.progress * 22) +"%)";
      })
  }

  render() {
    const { t } = this.props;

    return (
      <div className="uk-section uk-section-default uk-padding-remove-bottom uk-margin-medium-top" id="phonecover">
        <div className="uk-container uk-container-medium">
          <div className="uk-child-width-1-2@m uk-grid uk-text-center" data-uk-grid>
              <div className="uk-flex uk-flex-center uk-flex-column bm-phonecover__left">
                <div className="uk-flex uk-flex-center uk-flex-column uk-flex-1 uk-height-medium">
                  <h2 className="bm-width--full">{t("Handyhülle")}</h2>
                  <p>
                    <Link className="uk-button uk-button-primary uk-button-large uk-margin-small-bottom bm-shop__card__buy" to="#shop" data-uk-scroll>{t("JETZT KAUFEN")}</Link>
                  </p>
                </div>
                <div className="uk-flex uk-flex-1 uk-flex-bottom uk-flex-center bm-phonecover__left__img">

                  <img src={phoneBackStatic} alt="CoverOpen" id="phoneBackStatic" className="uk-position-absolute"/>
                  <img src={phoneBackMove1} alt="CoverOpen" id="phoneBackMove1" className="uk-position-relative"/>

                </div>
              </div>

              <div className="uk-flex uk-flex-center uk-flex-bottom bm-phonecover__right">

                <img src={phoneFrontMove1} alt="CoverOpen" id="phoneFrontMove1" className="uk-position-absolute"/>
                <img src={phoneFrontStatic} alt="CoverOpen" id="phoneFrontStatic" className="uk-position-absolute"/>
                <img src={phoneFrontMove2} alt="CoverOpen" id="phoneFrontMove2" className="uk-position-absolute"/>

              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default withNamespaces("PhoneCover")(PhoneCover);
